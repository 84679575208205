@keyframes gradient-animation {
  0% {
    background-position: 0% 50%; /* Start position */
  }
  50% {
    background-position: 100% 50%; /* Mid position */
  }
  100% {
    background-position: 0% 50%; /* Back to start */
  }
}

.animate-gradient {
  animation: gradient-animation 5s ease-in-out infinite; /* Smooth infinite animation */
}
