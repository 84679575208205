@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary: 200, 100%, 50%; /* Adjust as needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hide-scrollbar {
  overflow: auto; /* Allows scrolling */
  scrollbar-width: none; /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and newer Edge */
}
